import React from 'react'
import * as styles from './search-products.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import ProductCard from '../../Shared/ProductCard/ProductCard'
const SearchProducts = ({ array }) => {
    return (
        <div>
            <div>
                <h3>Products</h3>
                <span>{array.products.length > 3 ? '3' : array.products.length} out of {array.lengths}</span>
                <ul>
                    {
                        array.products.map(({ node }, index) => index < 3 && (
                            <li key={index}>
                                <ProductCard heroImage={node.heroImage} title={node.title} slug={node.slug} />
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default SearchProducts