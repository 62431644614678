import React, { useEffect, useState } from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import ComponentSearch from '../components/Search/ComponentSearch/ComponentSearch';
import Seo from '../components/Shared/Seo/Seo';
import * as styles from '../styles/local.module.scss'

// import * as searchStyles from '../components/Search/ComponentSearch/component-search.module.scss'
const PageSearch = ({
    location,
    pageContext
}) => {
    const params = new URLSearchParams(location.search);
    const parameter1 = params.get("s");
    const [productsLength, setProductsLength] = useState([])
    const [articlesLength, setArticlesLength] = useState([])
    const {
        allContentfulPageProduct,
        allContentfulPageArticle
    } = useStaticQuery(
        graphql`
        {
            allContentfulPageProduct {
                edges {
                    node {
                        node_locale
                        title
                        slug
                        heroImage {
                            gatsbyImageData
                        }
                    }
                }
            }
            allContentfulPageArticle {
                edges {
                    node {
                        node_locale
                        title: thumbnailTitle
                        slug
                        thumbnailImage {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        `
    )
    const allProducts = allContentfulPageProduct.edges.filter(({ node }) => node.title && node.slug && node.node_locale === pageContext.node_locale)
    const allArticles = allContentfulPageArticle.edges.filter(({ node }) => node.title && node.slug && node.node_locale === pageContext.node_locale)
    const [products, setProducts] = useState([])
    const [articles, setArticles] = useState([])

    // const findValue = (e) => {
    //     let products = allProducts?.filter(({ node }) => node.title.toLowerCase().includes(e.target.value.toLowerCase()))
    //     setProducts(products)

    //     let articles = allArticles?.filter(({ node }) => node.title.toLowerCase().includes(e.target.value.toLowerCase()))
    //     setArticles(articles)
    // }

    useEffect(() => {
        if (parameter1) {
            let products = allProducts?.filter(({ node }) => node.title.toLowerCase().includes(parameter1))
            setProducts(products)

            let articles = allArticles?.filter(({ node }) => node.title.toLowerCase().includes(parameter1))
            setArticles(articles)
        }
    }, [parameter1])

    console.log(location);

    return (
        <div>
            <h1 style={{ display: 'none' }}>'Search'</h1>
            <Seo
                title='Search'
                description="Search Page"
                lang={pageContext.node_locale}
                slug={location.pathname.slice(1)}
            />
            <div className={styles.container}>
                {/* <input type="text" onChange={(e) => findValue(e)} /> */}
                <ComponentSearch
                    products={{
                        products: products,
                        lengths: allProducts.length
                    }}
                    articles={{
                        articles: articles,
                        lengths: allProducts.length
                    }}
                    searchValue={parameter1}
                />
                {/* <div>
                    {
                        products?.map(({ node }, index) => {
                            return (
                                <div key={index}>
                                    <Link to={useLocalePath(node.slug)}>{node.title}</Link>
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        articles?.map(({ node }, index) => {
                            return (
                                <div key={index}>
                                    <Link to={useLocalePath(node.slug)}>{node.title}</Link>
                                </div>
                            )
                        })
                    }
                </div> */}
            </div>
        </div >
    )
}

export default PageSearch