import React from 'react'
import * as styles from './search-articles.module.scss';
import ArticleCard from '../../Shared/ArticleCard/ArticleCard'
const SearchArticles = ({ array }) => {
    return (
        <div>
            <div>
                <h3>Articles</h3>
                <p>{array.articles.length} out of {array.lengths}</p>
                <ul>
                    {
                        array.articles.map(({ node }, index) => (
                            <li key={index}>
                                <ArticleCard thumbnailImage={node.thumbnailImage} title={node.title} slug={node.slug} />
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default SearchArticles