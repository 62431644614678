import React from 'react'
import SearchArticles from '../SearchArticles/SearchArticles'
import SearchProducts from '../SearchProducts/SearchProducts'
import * as styles from './component-search.module.scss'

const ComponentSearch = ({
    products,
    articles,
    searchValue
}) => {
    return (
        <div>
            <div className={styles.container}>
                <h2 className={styles.title}>Search Results</h2>
                <p className={styles.subTitle}>You search for "{searchValue}"</p>
                <div>
                    <SearchProducts array={products} />
                </div>
                <div>
                    <SearchArticles array={articles} />
                </div>
            </div>
        </div>
    )
}

export default ComponentSearch